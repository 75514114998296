import React from 'react'
import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Content from './content'
import Banner from '../../../components/molecules/Banner'
import content from './content.json'

const { relational } = content

const Foxton = () => {
  return (
    <Layout page="Foxton">
      <Banner
        alt="Reprodução Foxton"
        image="foxton-banner.webp"
        objectPosition="28% center"
      />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}

export const Head = () => <Seo title="Foxton" />

export default Foxton
